import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 40px;
  width: 90%;
  margin: 0 auto;
  max-width: 460px;
  @media (max-width: 500px) {
    width: 90%;
  }
  svg {
    position: relative;
    width: 28px;
  }
  .behance {
    width: 36px;
  }
  .dribbble {
    width: 28px;
  }
  .linkedin {
    top: -3px;
    width: 32px;
  }
  a,
  svg {
    width: 30px;
    height: 30px;
    @media (max-width: 500px) {
      flex: 0 100%;
      height: 30px;
      text-align: center;
      margin: 0 auto;
    }
  }
  a {
    opacity: 1;
    transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
    flex: 0 100%;
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`

function Social() {
  return (
    <Container className="social">
      <a href="https://www.linkedin.com/in/daviddortiz/" target="_blank" rel="noopener noreferrer">
        <svg className="linkedin" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.13 25V8.13168H0.342145V25H6.13ZM3.23605 5.82859C5.25439 5.82859 6.51064 4.53329 6.51064 2.91454C6.47303 1.25936 5.25439 5.90367e-07 3.27434 5.90367e-07C1.29448 5.90367e-07 0 1.25936 0 2.91454C0 4.53329 1.25598 5.82859 3.19832 5.82859H3.23594H3.23605Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.3327 25H15.1205V15.58C15.1205 15.0759 15.1581 14.5722 15.3109 14.2118C15.7294 13.2046 16.6817 12.1613 18.2806 12.1613C20.3749 12.1613 21.2127 13.7082 21.2127 15.9758V25H27.0001V15.3279C27.0001 10.1468 24.1447 7.73585 20.3367 7.73585C17.2143 7.73585 15.8436 9.42648 15.082 10.578H15.1207V8.13168H9.33286C9.40881 9.71449 9.33286 25 9.33286 25H9.3327Z"
            fill="white"
          />
        </svg>
      </a>

      <a href="https://medium.com/@daviddortiz" target="_blank" rel="noopener noreferrer">
        <svg className="medium" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.95"
            d="M3.32026 4.48795C3.35505 4.14777 3.22398 3.81155 2.96732 3.5826L0.352941 0.465608V0H8.47059L14.7451 13.619L20.2614 0H28V0.465608L25.7647 2.58671C25.572 2.73209 25.4764 2.97103 25.5163 3.20753V18.7925C25.4764 19.029 25.572 19.2679 25.7647 19.4133L27.9477 21.5344V22H16.9673V21.5344L19.2288 19.3616C19.451 19.1417 19.451 19.077 19.451 18.7407V6.14345L13.1634 21.9483H12.3137L4.99346 6.14345V16.736C4.93243 17.1814 5.08188 17.6297 5.39869 17.9518L8.33987 21.4827V21.9483H0V21.4827L2.94118 17.9518C3.25569 17.6292 3.39642 17.1779 3.32026 16.736V4.48795Z"
            fill="white"
          />
        </svg>
      </a>

      <a href="https://twitter.com/daviddortiz" target="_blank" rel="noopener noreferrer">
        <svg className="twitter" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.95"
            d="M8.80579 23C19.3725 23 25.1507 14.1521 25.1507 6.47974C25.1507 6.22842 25.1456 5.97824 25.1345 5.7292C26.2561 4.90961 27.2311 3.88682 28 2.72253C26.9707 3.18496 25.863 3.49627 24.701 3.63664C25.8871 2.9178 26.7977 1.78068 27.2272 0.425072C26.1173 1.09014 24.8881 1.57352 23.5793 1.83445C22.531 0.705818 21.0386 0 19.386 0C16.2136 0 13.6409 2.60027 13.6409 5.80561C13.6409 6.26125 13.6913 6.70444 13.7899 7.12952C9.01525 6.8867 4.78161 4.57623 1.94855 1.06298C1.45518 1.92105 1.1707 2.9178 1.1707 3.98134C1.1707 5.99579 2.18487 7.77421 3.72712 8.81454C2.78463 8.78511 1.89927 8.52361 1.12534 8.08835C1.12449 8.11271 1.12449 8.13646 1.12449 8.16249C1.12449 10.9745 3.10467 13.3223 5.73334 13.8543C5.25061 13.9873 4.74269 14.0587 4.21852 14.0587C3.84892 14.0587 3.48883 14.0219 3.13883 13.954C3.8702 16.261 5.99094 17.9398 8.50537 17.9868C6.53919 19.5445 4.06228 20.4722 1.37034 20.4722C0.90721 20.4722 0.449685 20.4456 0 20.3918C2.54243 22.0389 5.56141 23 8.80608 23"
            fill="white"
          />
        </svg>
      </a>

      <a href="https://github.com/daviddortiz" target="_blank" rel="noopener noreferrer">
        <svg className="github" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 0C6.265 0 0 6.265 0 14C0 20.195 4.0075 25.4275 9.5725 27.2825C10.2725 27.405 10.535 26.985 10.535 26.6175C10.535 26.285 10.5175 25.1825 10.5175 24.01C7 24.6575 6.09 23.1525 5.81 22.365C5.6525 21.9625 4.97 20.72 4.375 20.3875C3.885 20.125 3.185 19.4775 4.3575 19.46C5.46 19.4425 6.2475 20.475 6.51 20.895C7.77 23.0125 9.7825 22.4175 10.5875 22.05C10.71 21.14 11.0775 20.5275 11.48 20.1775C8.365 19.8275 5.11 18.62 5.11 13.265C5.11 11.7425 5.6525 10.4825 6.545 9.5025C6.405 9.1525 5.915 7.7175 6.685 5.7925C6.685 5.7925 7.8575 5.425 10.535 7.2275C11.655 6.9125 12.845 6.755 14.035 6.755C15.225 6.755 16.415 6.9125 17.535 7.2275C20.2125 5.4075 21.385 5.7925 21.385 5.7925C22.155 7.7175 21.665 9.1525 21.525 9.5025C22.4175 10.4825 22.96 11.725 22.96 13.265C22.96 18.6375 19.6875 19.8275 16.5725 20.1775C17.08 20.615 17.5175 21.455 17.5175 22.7675C17.5175 24.64 17.5 26.145 17.5 26.6175C17.5 26.985 17.7625 27.4225 18.4625 27.2825C23.9925 25.4275 28 20.1775 28 14C28 6.265 21.735 0 14 0Z"
            fill="#F5F5F6"
          />
        </svg>
      </a>

      <a href="https://www.behance.net/daviddortiz" target="_blank" rel="noopener noreferrer">
        <svg className="behance" viewBox="0 0 365 229" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M106 0C116.5 0 126.2 0.8 135.1 3.2C144 4.8 151.3 8.1 157.8 12.1C164.3 16.1 169.1 21.8 172.4 29.1C175.6 36.4 177.3 45.3 177.3 55C177.3 66.3 174.9 76 169.2 83.3C164.3 90.6 156.3 97.1 146.5 101.9C160.3 105.9 170.8 113.2 177.2 122.9C183.6 132.6 187.7 144.7 187.7 158.5C187.7 169.8 185.3 179.5 181.2 187.6C177.2 195.7 170.7 203 163.4 207.8C156.1 212.7 147.2 216.7 137.5 219.1C127.8 221.5 118.1 223.1 108.4 223.1L0 223.3V0H106ZM99.5 90.6C108.4 90.6 115.7 88.2 121.3 84.1C126.9 80 129.4 72.8 129.4 63.9C129.4 59 128.6 54.2 127 51C125.4 47.8 123 45.3 119.7 42.9C116.5 41.3 113.2 39.7 109.2 38.9C105.2 38.1 101.1 38.1 96.3 38.1H49.4V90.7C49.4 90.6 99.5 90.6 99.5 90.6ZM101.9 186.1C106.8 186.1 111.6 185.3 115.7 184.5C119.7 183.7 123.8 182.1 127 179.6C130.2 177.1 132.7 174.7 135.1 170.7C136.7 166.7 138.3 161.8 138.3 156.1C138.3 144.8 135.1 136.7 128.6 131C122.1 126.1 113.2 123.7 102.7 123.7H49.4V186H101.9V186.1Z"
            fill="white"
          />
          <path
            d="M258.1 185.3C264.6 191.8 274.3 195 287.2 195C296.1 195 304.2 192.6 310.7 188.5C317.2 183.6 321.2 178.8 322.8 173.9H362.4C355.9 193.3 346.2 207.1 333.3 216C320.4 224.1 305 228.9 286.4 228.9C273.5 228.9 262.1 226.5 251.6 222.4C241.1 218.4 233 212.7 225.7 204.6C218.4 197.3 212.8 188.4 209.5 177.9C205.5 167.4 203.8 156.1 203.8 143.1C203.8 131 205.4 119.6 209.5 109.1C213.6 98.5999 219.2 89.6999 226.5 81.5999C233.8 74.2999 242.7 67.7999 252.4 63.7999C262.9 59.7999 273.4 57.2999 286.4 57.2999C300.2 57.2999 312.3 59.6999 322.8 65.3999C333.3 71.0999 341.4 77.4999 347.9 87.1999C354.4 96.0999 359.2 106.6 362.5 117.9C364.1 129.2 364.9 140.6 364.1 153.5H246.8C246.8 166.7 251.6 178.8 258.1 185.3ZM309.1 100.3C303.4 94.5999 294.5 91.3999 284 91.3999C276.7 91.3999 271.1 92.9999 266.2 95.3999C261.3 97.7999 258.1 101.1 254.9 104.3C251.7 107.5 250 111.6 249.2 115.6C248.4 119.6 247.6 122.9 247.6 126.1H320.4C318.8 114.1 314.7 106 309.1 100.3ZM237.9 15.3999H328.5V37.1999H237.9V15.3999Z"
            fill="white"
          />
        </svg>
      </a>

      {/* <a
        href="https://dribbble.com/daviddortiz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          className="dribbble"
          viewBox="0 0 203 203"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101.5 0C45.4659 0 0 45.4659 0 101.5C0 157.534 45.4659 203 101.5 203C157.424 203 203 157.534 203 101.5C203 45.4659 157.424 0 101.5 0ZM168.543 46.7868C180.652 61.5384 187.918 80.3634 188.138 100.729C185.276 100.179 156.653 94.3444 127.811 97.9771C127.15 96.5462 126.6 95.0048 125.939 93.4636C124.178 89.2804 122.196 84.987 120.215 80.9138C152.14 67.9236 166.671 49.2088 168.543 46.7868ZM101.5 14.9718C123.517 14.9718 143.663 23.2283 158.965 36.769C157.424 38.9707 144.324 56.4746 113.5 68.0336C99.2983 41.943 83.5558 20.5862 81.134 17.2836C87.629 15.7424 94.4545 14.9718 101.5 14.9718ZM64.621 23.1182C66.9328 26.2006 82.3449 47.6676 96.7662 73.2077C56.2543 83.9961 20.4762 83.7761 16.6231 83.7761C22.2374 56.9149 40.4019 34.5672 64.621 23.1182ZM14.7516 101.61C14.7516 100.729 14.7516 99.8488 14.7516 98.968C18.4946 99.078 60.5478 99.6285 103.812 86.6384C106.344 91.4822 108.656 96.436 110.857 101.39C109.756 101.72 108.546 102.051 107.445 102.381C62.7495 116.802 38.9707 156.213 36.9892 159.516C23.2283 144.214 14.7516 123.848 14.7516 101.61ZM101.5 188.248C81.4643 188.248 62.9696 181.423 48.328 169.974C49.8694 166.782 67.4833 132.875 116.362 115.811C116.582 115.701 116.692 115.701 116.912 115.591C129.132 147.186 134.086 173.717 135.407 181.313C124.949 185.826 113.5 188.248 101.5 188.248ZM149.828 173.387C148.947 168.102 144.324 142.782 132.985 111.628C160.176 107.335 183.955 114.38 186.927 115.371C183.184 139.48 169.313 160.286 149.828 173.387Z"
            fill="white"
          />
        </svg>
      </a> */}
    </Container>
  )
}

export default Social
